import React, {createRef} from 'react';
import {Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import {withStyles} from "@material-ui/core/styles";
import {isMobile, isNumeric, lengthBetween} from "../util/Validator";
import IconButton from "@material-ui/core/IconButton";
import {showError} from "../panel/components/Notifier";
import {LOGIN} from "../constants/Units";
import {compose} from "redux";
import {connect} from "react-redux";
import {resendSignUp, setSignupStep, updateCaptchaVersion, userSignUp, verifyUserSignUp} from "../actions";
import RefreshIcon from "@material-ui/icons/Refresh";
import Dialog from "@material-ui/core/Dialog";
import WebsiteRulesDialog from "../panel/routes/components/websiteRulesDialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {fa2enNumbers} from "../util/Utilities";
import BrowserAlert from "./components/BrowserAlert";

import ReactCodeInput from "react-code-input";
import {BRANCH_ESSENTIALS} from "../panel/routes/data/constants";
const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


const emailRegex = RegExp(
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
);
const phoneRegex = RegExp(/^[+][0-9 ]{9,20}$/);

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const formValid = ({formErrors, ...rest}) => {
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};

// let history = useHistory();

class SignUp extends React.Component {

    constructor(props) {
        super(props);
        this.timer = 0;
        // this.startTimer = this.startTimer.bind(this);
        // this.countDown = this.countDown.bind(this);

        // this.formValid = this.formValid.bind(this);
    }

    state = {
        userInfo: {
            email: '',
            phone_number: '',
            password: '',
            // referral: "",

        },
        formErrors: {
            captcha: "",
            email: '',
            phone_number: '',
            password: '',
            // referral: "",
            rulesChecked: ""
        },
        refs: {
            captcha: createRef()
        },
        verifyCode: '',
        captcha: "",
        error: {},
        loading: false,
        success: false,
        activeStep: 0,
        formValid: {},
        formToken: '',
        userInputName: 'emailOrPhone',
        showPassword: false,
        time: {},
        seconds: "",
        resendLeftSecs: 120,
        linkReferral: '',
        openWebsiteRules: false,
        rulesChecked: false

    };

    handleRequestClose = () => {
        this.setState({openWebsiteRules: false})
    };

    handleClickOpen = () => {
        this.setState({openWebsiteRules: true});
    };

    handleCheck = e => {
        const {name, checked} = e.target;
        const {formErrors, rulesChecked} = this.state;
        this.setState({[name]: !!checked});
        formErrors.rulesChecked =
            !!rulesChecked ? "قوانین وبسایت را چک کنید." : "";
    };

    componentWillMount() {

        let referral = (window.location.pathname.replace('/landing/about', '').replace('/landing/rules', '')
            .replace('/landing/', '').replace('/landing', '')
            .replace('/signup/', '').replace('/signup', '')|| localStorage.getItem('referral') || '');
        if (referral) {
            localStorage.setItem('referral', referral);
            const {userInfo} = this.state;
            userInfo.referral = referral;
            this.setState({linkReferral: referral, userInfo: userInfo});

        }
    };

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleNext = (e) => {
        e.preventDefault();
        const {signupStep} = this.props;
        const {rulesChecked} = this.state;
        if (signupStep === 1) {
            const {userInfo, userInputName, captcha, formErrors} = this.state;
            if (!!formErrors.password || !!formErrors[userInputName] || captcha.length !== 6 || rulesChecked === false) {
                showError(LOGIN, 'form.invalid');

                return;
            }

            let fields = {
                password: userInfo.password,
                // referral: userInfo.referral,
                captcha: captcha,
                terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN,
                terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID
            };
            fields[userInputName] = userInfo[userInputName];
            this.props.userSignUp(fields);
        } else if (signupStep === 2) {
            if (!formValid(this.state)) {
                showError(LOGIN, 'form.invalid');
                return;
            }
            this.props.verifyUserSignUp({token: this.props.signupVerifyDetails.token, code: this.state.verifyCode});

        }
    };

    componentWillUnmount() {
        this.stopTimer();
    }

    handleBack = () => {
        const {signupStep} = this.props;
        this.props.setSignupStep(signupStep - 1);
        this.setState({
            email: "",
            phone_number: "",
            password: "",
        });
    };

    changeVerifyCode = value => {
        let formErrors = {...this.state.formErrors};
        if (!isNumeric({input: value}))
            formErrors.verifyCode = <IntlMessages id="error.content.verify_code.numeric"/>;
        else if (!lengthBetween({input: value, min: 6, max: 6}))
            formErrors.verifyCode = <IntlMessages id="error.content.verify_code.length"/>;
        else
            formErrors.verifyCode = "";
        this.setState({formErrors, 'verifyCode': value},);
    }

    handleChange = e => {
        // localStorage.clear();
        e.preventDefault();
        let {name, value} = e.target;
        let formErrors = {...this.state.formErrors};
        value = fa2enNumbers(value);
        switch (name) {
            case this.state.userInputName:
                formErrors.email = '';
                if (emailRegex.test(value)) {
                    this.state.userInputName = 'email';
                    this.state.userInfo.email = value;
                } else if (isMobile({input: value})) {
                    this.state.userInputName = 'phone_number';
                    this.state.userInfo.phone_number = value;
                } else {
                    formErrors.email = <IntlMessages id="error.content.email_mobile.invalid"/>;
                    this.state.userInputName = 'emailOrPhone';
                    this.state.userInfo.phone_number = '';
                    this.state.userInfo.email = '';
                }
                break;
            case "password":
                formErrors.password =
                    value.length < 8 ? <IntlMessages id="error.content.password.length"/> : "";
                this.state.userInfo.password = value;
                break;
            case "referral":
                this.state.userInfo.referral = value;
                break;
            case "captcha":
                if(value.toString().length !== 6)
                    formErrors.captcha = <IntlMessages id="error.content.captcha.length"/>;
                else
                    formErrors.captcha = '';
                break;
            case "verifyCode":
                formErrors.verifyCode =
                    value.length < 5 ? <IntlMessages id="error.content.auth_code.invalid"/> : "";
                break;
            default:
                break;
        }
        this.setState({formErrors, [name]: value},);
    };

    handleResendCode = (e) => {
        e.preventDefault();
        this.props.resendSignUp({
            token: this.props.signupVerifyDetails.token,
            // terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN,
            // terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID
        });
    };

    startTimer() {
        const {signupVerifyDetails} = this.props;
        const now = Date.now() / 1000;
        if (signupVerifyDetails && signupVerifyDetails.next_request > now && !this.timerStarted) {
            this.timer = setInterval(() => {
                const resendLeftSecs = this.props.signupVerifyDetails.next_request - parseInt(Date.now() / 1000);
                this.setState({resendLeftSecs: resendLeftSecs});
                if (resendLeftSecs === 0)
                    this.stopTimer();
            }, 1000);
            this.timerStarted = true;
        }

    }

    stopTimer() {
        if (this.timerStarted && this.timer) {
            clearInterval(this.timer);
            this.timerStarted = false;
        }
    }

    updateRecaptcha = () => {
        this.props.updateCaptchaVersion();
        if (this.state.refs.captcha.current) {
            this.state.refs.captcha.current.focus();
        }
    }

    getVerifyCode() {
        const {signupVerifyDetails} = this.props;
        return <>
            <div className="enter-code-text">
                {signupVerifyDetails.field == "mobile" ?
                    <>لطفا کد 6 رقمی ارسال شده به شماره <div className="mobile-num">{signupVerifyDetails.mobile}</div> را وارد
                        نمایید</>
                    : signupVerifyDetails.field == "email" ?
                        <>لطفا کد 6 رقمی ارسال شده به ایمیل <div className="mobile-num">{signupVerifyDetails.email}</div> را
                            وارد نمایید</>
                        : ""
                }
            </div>
            {/*<div className="d-inline-flex">*/}
            {/*    <div className="jr-fs-xl text-danger">*/}
            {/*        اعتبار تا :*/}
            {/*    </div>*/}
            {/*    <span className="text-primary jr-fs-xl">*/}
            {/*    {" " + signupVerifyDetails.code_validity / 60 + " " + "دقیقه" + " "}*/}
            {/*</span>*/}
            {/*</div>*/}
            <div className="confirm-code-input">
                <ReactCodeInput
                    value={this.state.verifyCode}
                    inputRef={this.state.refs.code}
                    id="verifyCode"
                    name="verifyCode"
                    // label={<IntlMessages id="title.verify_code"/>}
                    // fullWidth
                    // value={inputs.verifyCode}
                    margin="normal"
                    onChange={this.changeVerifyCode}
                    // onChange={this.changeVerifyCode}
                    error={this.state.error.verifyCode}
                    // error={!!formErrors.verifyCode}
                    // className={formErrors.verifyCode ? "error" : null}
                    type='number' fields={6}
                />
            </div>
            {/*<TextField*/}
            {/*    type="text"*/}
            {/*    id="verifyCode"*/}
            {/*    name="verifyCode"*/}
            {/*    autoComplete='off'*/}
            {/*    label={<IntlMessages id="appModule.verifycode"/>}*/}
            {/*    fullWidth*/}
            {/*    inputProps={{tabIndex: "1"}}*/}
            {/*    autoFocus={true}*/}
            {/*    // defaultValue={this.state.password}*/}
            {/*    // onChange={(event) => this.setState({password: event.target.value})}*/}
            {/*    margin="normal"*/}
            {/*    className="mt-1"*/}
            {/*    onChange={this.handleChange}*/}
            {/*    error={this.state.error.verifyCode}*/}
            {/*    variant="outlined"*/}
            {/*    size="small"*/}
            {/*/>*/}

            {this.state.resendLeftSecs === 0 ?
                <div className="resend-text-link" onClick={this.handleResendCode}>
                    {/*<Link to="" >*/}
                    ارسال مجدد کد
                    {/*</Link>*/}
                </div>

                :
                <div className="seconds-text">
                    {this.state.resendLeftSecs === 0 ? "ارسال مجدد کد" : "ارسال مجدد تا" + " " + this.state.resendLeftSecs + " " + "ثانیه"}
                </div>
            }

            {signupVerifyDetails.field == "email" ?
                <div className="check-email">
                    لطفا پوشه اسپم ایمیل خود را نیز بررسی نمائید.
                </div>
                : ""
            }

            <TextField
                className="d-none"
                type="hidden"
                id="verifyCode"
                name="verifyCode"
                value={this.state.formToken}

            />

        </>
    }

    getUserInfo() {
        const {darkTheme} = this.props;
        return (
            <>
                <BrowserAlert/>
                <div className="email-phone-input">
                    <TextField
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        id="emailOrPhoneNo"
                        // label={<IntlMessages id="appModule.email-or-mobile"/>}
                        // value={this.state.userInfo.email}
                        fullWidth
                        autoFocus={true}
                        inputProps={{tabIndex: "1"}}
                        margin="normal"
                        className={this.state.formErrors.email ? "error" : null}
                        placeholder="شماره موبایل یا ایمیل"
                        type="email"
                        name={this.state.userInputName}
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.email}
                        variant="outlined"
                        size="small"
                    />
                    {this.state.formErrors.email && (
                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.email}</div>

                    )}
                </div>
                <div className="password-input">
                    <TextField
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        id="password"
                        // value={this.state.userInfo.password}
                        // label={<IntlMessages id="appModule.password"/>}
                        fullWidth
                        inputProps={{tabIndex: "2"}}
                        margin="normal"
                        className={this.state.formErrors.password ? "error" : null}
                        placeholder="رمز عبور"
                        type={this.state.showPassword ? 'text' : 'password'}
                        name="password"
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.password}
                        // value={this.state.password}
                        variant="outlined"
                        size="small"
                        InputProps={{

                            endAdornment:
                                <IconButton
                                    tabIndex={-1}
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                >
                                    {this.state.showPassword ? <img src={require("../assets/images/mars/hide.svg")}/> :
                                        <img src={require("../assets/images/mars/show.svg")}/>}
                                </IconButton>


                        }}

                    />
                    {this.state.formErrors.password && (
                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.password}</div>

                    )}
                </div>
                <div className="referral-input">
                    <TextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        id="referral"
                        // value={this.state.userInfo.password}
                        // label={<IntlMessages id="appModule.referral"/>}
                        fullWidth
                        inputProps={{tabIndex: "3"}}
                        margin="normal"
                        disabled={!!this.state.linkReferral}
                        className={this.state.formErrors.referral ? "error" : null}
                        placeholder="کد دعوت"
                        type='text'
                        name="referral"
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.referral}
                        variant="outlined"
                        size="small"
                        value={this.state.userInfo.referral}

                    />
                    {this.state.formErrors.referral && (
                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.referral}</div>

                    )}
                </div>
                <div className="captcha-row">
                    <div className="captcha-input">
                        <TextField
                            id="captcha"
                            // label={<IntlMessages id="appModule.captcha_input"/>}
                            // value={this.state.userInfo.email}
                            fullWidth
                            placeholder="کد امنیتی"
                            inputRef={this.state.refs.captcha}
                            inputProps={{tabIndex: "4"}}
                            autoComplete='off'
                            margin="normal"
                            className={this.state.formErrors.captcha ? "error" : null}
                            type="number"
                            name='captcha'
                            noValidate
                            onChange={this.handleChange}
                            error={!!this.state.formErrors.captcha}
                            variant="outlined"
                            size="small"
                        />
                        {this.state.formErrors.captcha  && (
                            <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.captcha}</div>
                        )}
                    </div>
                    <div className='captcha-img-wrapper'>
                        <div className="captcha-refresh-btn">
                            <IconButton
                                tabIndex={-1}
                                onClick={this.updateRecaptcha}
                            >
                                <RefreshIcon/>
                            </IconButton>
                        </div>
                        <div className="captcha-img">
                            <img src={'/core/api/captcha/register?v=' + this.props.captchaVersion}
                                 alt='Register Captcha'/>
                        </div>
                    </div>
                </div>
                <div className="rules-check-wrapper">
                    <FormControlLabel
                        control={
                            <Checkbox className="rules-checkbox" tabIndex={5} checked={this.state.rulesChecked} onChange={this.handleCheck}
                                      name="rulesChecked"/>
                        }
                        label={<div className="rules-desc">
                            <div className="rules-link" onClick={this.handleClickOpen}>
                                قوانین و مقررات
                            </div>
                            {" "}
                            وبسایت را مطالعه کرده و پذیرفته ام
                        </div>
                        }
                    />
                    {/*{this.state.formErrors.rulesChecked.length > 0 && (*/}
                    {/*    <div style={{fontSize: "small", position: "absolute", marginTop: "0", marginRight: '24px'}}>*/}
                    {/*        <span className="text-danger small">{this.state.formErrors.rulesChecked}</span>*/}
                    {/*    </div>*/}

                    {/*)}*/}


                </div>

            </>
        )


    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 1:
                this.stopTimer();
                return this.getUserInfo();
            case 2:
                this.startTimer();
                return this.getVerifyCode();
            default:
                return <IntlMessages id="appModule.loginSuccessfully"/>;
        }
    }

    render() {
        const steps = 2;
        const {classes, signingUp, signupStep} = this.props;
        const {openWebsiteRules} = this.state
        return (
            <>
                <div className="login-wrapper">
                    <div className="login-header-wrapper">
                        <div>
                            ثبت نام | خوش آمدید!
                        </div>

                        برای شروع شماره موبایل یا ایمیل و رمز عبور خود را وارد نمایید:
                        {/*<IntlMessages id="home.header.signup"/>*/}
                    </div>
                    <div className="login-form">
                        <form>
                            <fieldset className={signupStep === 2 ? "second-step" : " register-fieldset"}>
                                {this.getStepContent(signupStep)}

                                {signupStep === 1 ?
                                    <div className="button-wrapper">
                                        <Button
                                            variant="contained"
                                            className=" confirm-btn"
                                            disabled={signingUp}
                                            onClick={this.handleNext}
                                            fullWidth
                                            type="submit"
                                        >
                                            {signupStep === 1 ? <IntlMessages id="appModule.signup"/> : "تایید"}
                                        </Button>
                                        {signingUp &&
                                            <CircularProgress size={24} style={{zIndex: '9'}}
                                                              className={classes.buttonProgress}/>}
                                    </div>
                                    :
                                    <div className="buttons-wrapper">
                                        <div className="confirm-btn-wrapper">
                                            <Button
                                                variant="contained"
                                                className=" confirm-btn"
                                                disabled={signingUp}
                                                onClick={this.handleNext}
                                                fullWidth
                                                type="submit"
                                            >
                                                {signupStep === 1 ?
                                                    <IntlMessages id="appModule.signup"/> : "تایید"}
                                            </Button>
                                            {signingUp &&
                                                <CircularProgress size={24} style={{zIndex: '9'}}
                                                                  className={classes.buttonProgress}/>}
                                        </div>
                                        <div className="cancel-btn-wrapper">
                                            <Button
                                                onClick={this.handleBack}
                                                className="cancel-btn btn-disable"
                                                fullWidth
                                            >
                                                <IntlMessages id="appModule.back"/>
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </fieldset>
                        </form>
                    </div>

                </div>


                <Dialog open={openWebsiteRules} className='website-rules-dialog' onClose={this.handleRequestClose} maxWidth={"sm"} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleRequestClose}/>
                    <WebsiteRulesDialog/>
                </Dialog>
            </>


        );
    };
}


const mapStateToProps = ({auth, settings}) => {
    const {darkTheme} = settings;
    const {signingUp, tokenReceiveTime, signupStep, signupVerifyDetails, captchaVersion} = auth;
    return {signingUp, tokenReceiveTime, signupStep, signupVerifyDetails, captchaVersion, darkTheme};
};

export default compose(
    connect(
        mapStateToProps,
        {userSignUp, verifyUserSignUp, setSignupStep, updateCaptchaVersion, resendSignUp},
    ),
    withStyles(styles)
)(SignUp)



